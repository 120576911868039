/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.ProductPage {
    margin-block-start: 0;
    
    @include desktop {
        max-width: 1170px;
        width: 100%;
        margin-inline: auto;
    }

    @include mobile {
        max-width: 490px;
        width: 100%;
        margin-inline: auto;
        padding: 0 15px;
    }

    @media (max-width: 1200px ) {
        padding-inline: 15px;
    }

    @media (min-width: 1600px) {
        max-width: 1405px;
        width: 100%;
    }

    a.ProductActions-Schema-Url {
        display: none;
    }

    .ProductGallery-Slider {
        min-height: unset;
        height: auto;
        margin-block: 15px 20px;
        line-height: 0;

        @include desktop {
            margin-block: 0;
        }

        .ProductGallery-SliderImage {
            .Image-Image {
                position: static;
                max-height: 516px;
                border-radius: 3px;
            }
        }

        .react-transform-element {
            transform: unset !important;
        }
        
        @include mobile {
            img {      
                -webkit-touch-callout: none;      
                -webkit-user-select: none;      
                -khtml-user-select: none;     
                -moz-user-select: none;      
                -ms-user-select: none;      
                user-select: none;      
                -webkit-tap-highlight-color: transparent;    
            }
        }
    }

    .Slider-Crumb {
        width: 10px;
        height: 10px;
        background-color: #d6d6d6;
        border-color: #d6d6d6;

        &_isActive {
            background-color: #2db0bc;
            border: none;
        }
    }

    .ProductActions {
        .ProductName {
            text-transform: uppercase;
            line-height: 1;
            color: #303030;
            font-weight: 400 !important;
            margin-block-end: 2px;

            @include mobile {
                font-size: 20px;
            }

            @include desktop {
                font-size: 24px;
            }

            @media (min-width: 1600px) {
                font-size: 30px;
            }
        }

        .price-amznstyle {
            color: var(--text-red-color);
            font-size: 30px;
            font-family: regular-bold, sans-serif;
            font-weight: 400;
            text-align: start;
            padding-block-start: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @include mobile {
                font-size: 24px;
            }

            .price-symbol {
                inset-block-start: -10px;
                right: 1px;
                font-size: 16px;
            }

            .price-whole {
                font-size: 32px;
            }

            .price-decimal {
                top: -10px;
                left: 1px;
                font-size: 16px;
            }

            .offscreen {
                position: absolute !important;
                inset-inline-start: 0 !important;
                inset-block-end: -1px !important;
                z-index: -1 !important;
                opacity: 0;
            }
        }

        .price-new {
            display: none;
        }

        .price-current {
            display: block;

            @include mobile {
                display: flex;
                flex-direction: row;
                gap: 8px;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
            }
        }

        span {
            &.price-box-text {
                display: block;
                font-size: 10px;
                line-height: 1;
                color: var(--text-light-black-color);
                font-weight: normal;
                font-family: regular-book, sans-serif;
            }
        }

        .ProductPrice {
            &-HighPrice {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                margin-block: 1px;
                width: 100%;
                font-family: regular-bold, sans-serif;
                margin-bottom: 5px;

                del {
                    -webkit-margin-end: 0 !important;
                    font-size: 20px;
                    color: #888;
                    opacity: 1;
                    padding: 0;

                    @include mobile {
                        font-size: 20px;
                    }
                }
            }

            &-SaveDiscount {
                display: inline-block;
                color: #fff;
                font-size: 15px;
                font-weight: 400;
                background-color: #222;
                padding: 4px 5px;
                border-radius: 5px;
                text-transform: uppercase;
                margin-left: 5px;
            }
        }

        .price-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            margin-left: 1px;
            flex: 1;
            min-height: 48px;

            &-pills {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            @media (max-width: 1599px) {
                &.price-wrapper-column {
                    flex-direction: column;
                    align-items: flex-start;
                    min-height: auto;

                    .price-wrapper-pills {
                        margin-block-start: 8px;
                    }
                }
            }

            &-price {
                @include mobile {
                    width: 100%;
                }
            }
        }

        .fr_product_pills_wrapper {
            margin-left: 0;
            max-width: 220px;
            text-align: end;

            @include desktop {
                max-width: 300px;
                border: 1px #e2e2e2 solid;
                border-radius: 999px;
                padding: 5px 15px 5px 5px;
            }

            .fr_product_pills {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                font-family: regular-bold, sans-serif;
                text-align: center;
                color: #444;
                font-size: 16px;
                text-transform: uppercase;
                line-height: 1.3;
                min-width: 145px;
                word-break: break-word;

                @include mobile {
                    text-align: left;

                    &-condensed {
                        flex-direction: column;
                        align-items: center;
                        margin: 0;
                    }
                }

                &-icon {
                    flex: 1;
                    flex-basis: 36px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    // background: var(--text-blue-color);
                    padding: 5px;
                    width: 36px;
                    height: 36px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 999px;
                    margin-right: 8px;

                    @include mobile {
                        flex-basis: 32px;
                        width: 32px;
                        height: 32px;
                        line-height: 26px;
                    }

                    svg {
                        color: #fff;
                        font-size: 18px;
                    }
                }

                &-content {
                    flex: auto;

                    .title {
                        // color: var(--text-blue-color);
                        font-size: 19px;
                        line-height: 20px;

                        @include mobile {
                            font-size: 14px;
                        }
                    }

                    .des {
                        font-size: 11px;
                        font-family: regular-book, sans-serif;
                        margin-block-start: 1px;

                        @include mobile {
                            margin-block-start: 0px;
                        }

                        a {
                            color: inherit;
                            text-decoration: none;

                            &:hover {
                                color: var(--text-red-color);
                            }
                        }
                    }

                    @include mobile {
                        text-align: start;
                    }
                }

                @include desktop {
                    padding: 0;
                }

                &.fr_product_free_shipping {
                    .fr_product_pills-icon {
                        background: var(--text-blue-color);
                    }

                    .fr_product_pills-content .title {
                        color: var(--text-blue-color);
                    }
                }

                &.fr_product_money_back {
                    .fr_product_pills-icon {
                        background: #92A414;
                    }

                    .fr_product_pills-content .title {
                        color: #92A414;
                    }
                }
            }
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 6fr 4fr;
        grid-column-gap: 2rem;
        grid-template-rows: min-content min-content;
        padding-block-start: 2rem;

        .ProductGallery-Container {
            height: auto;
            margin: 0;
            position: relative;

            @include desktop {
                width: 55%;
                min-width: unset;

                @media (min-width: 1600px) {
                    width: 50%;
                }
            }

            .ProductGallery {
                height: auto;
                margin-inline: 0;

                @include desktop {
                    position: sticky;
                    inset-block-start: 162px;
                    z-index: 0;
                }

                @include mobile {
                    margin-block-end: 0;
                }

                &-SliderWrapper {
                    max-height: unset;
                    flex: unset;
                    overflow: hidden;

                    @include mobile {
                        min-height: 300px;
                    }

                    .Image {
                        height: auto;
                    }
                }
            }
        }

        .productLinks_wrp {
            margin-block-end: 30px;
            position: relative;

            @include mobile {
                min-height: 450px;
            }
        }

        @include desktop {
            padding: 15px 0 0 !important;
            display: flex;
            flex-wrap: wrap;
            grid-column-gap: unset;
            position: relative;

            article.ProductActions,
            .productLinks_wrp {
                width: 45%;
                padding-left: 30px;

                .ProductActions-Schema {
                    width: 100%;
                }

                @media (min-width: 1600px) {
                    width: 50%;
                    padding-left: 30px;
                }
            }
            // .productLinks_wrp {
            //     width: calc(45% - 30px);
            // }

            .AdditionalInfo {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                background-color: #fff;

                .product-info {
                    width: 55%;

                    @media (min-width: 1600px) {
                        width: 50%;
                    }
                }

                section.RelatedProducts {
                    width: 45%;
                    padding-inline: 20px 0;
                }
            }

            .RelatedProducts-Wrapper {
                padding: 0 5px 20px;
            }
        }
        //}

        .product_also_like {
            padding-block-end: 40px;

            @include mobile {
                min-height: 487px;
            }

            @include desktop {
                background: #f4f4f4;
                width: 100vw;
                min-width: 100vw;
                position: relative;
                left: calc(-50vw + 50%);
                padding: 0 25px 40px;

                .ProductLinks-Title {
                    font-size: 25px;
                    font-weight: 400;
                    text-align: center;
                    margin-block-start: 25px;
                }

                .product-slider .child {
                    li.ProductCard {
                        margin-block-end: 0;
                    }

                    .ProductCard-Name {
                        font-size: 16px;
                    }

                    .pro_inner_top {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: column;
                    }

                    .pro_inner_bot {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-block-start: 12px;

                        .ProductCard-SKU {
                            margin-block-start: 0;
                            margin-inline-end: 15px;
                        }
                    }
                }

                h3.ProductLinks-Title {
                    text-align: center;
                    font-size: 26px;
                    margin-block-start: 25px;
                }
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0 !important;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .RelatedProducts-Label,
    .BillOfMaterials h4,
    .ProductLinks-Title {
        text-transform: uppercase;
        font-family: regular-bold, sans-serif;
        font-size: 22px;
        font-weight: 400;
        color: var(--text-dark-gray-color);
        margin: 5px 0 17px;
        padding: 0;
    }

    .AdditionalInfo {
        #categoryIds_hidden {
            display: none !important;
        }

        .ProductLinks-Wrapper {
            padding: 0;

            .ProductLinks-List {
                display: block;

                .Loader {
                    margin-top: 40px;
                    position: static;
                }

                .ProductCard-Figure {
                    .Image-Image {
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .BillOfMaterials {
        word-wrap: break-word;
        word-break: break-word;
        margin-block-end: 25px;

        li {
            padding-left: 0;
            font-size: inherit;

            &::before {
                display: none;
            }
        }

        .materials-wrap {
            border: 1px solid #e2e2e2;
            padding: 15px;
            font-size: 17px;
            font-weight: 400;
            color: var(--text-light-black-color);
            line-height: 1;
            font-family: regular-book, sans-serif;

            ul {
                ul {
                    padding-left: 30px;
                    margin: 22px 0;
                }

                .sku {
                    display: block;
                    font-weight: 400;
                    text-decoration: underline;
                    color: var(--text-light-black-color);
                }

                .name {
                    color: var(--text-light-black-color);
                    margin-block-start: 10px;
                    display: block;
                }
            }
        }
    }

    & ~ .Footer {
        @include mobile {
            padding-block-end: calc(var(--header-nav-height) + 60px);
        }
    }

    & ~ .Breadcrumbs .Return-page {
        display: none;
    }

    .ProductReviews {
        .ProductReviewRating {
            svg.StarIcon {
                fill: var(--star-icon-color);
            }
        }

        &-SummaryRating {
            svg.StarIcon {
                width: 27px;
                height: 27px;
            }
        }

        .ProductReviewItem {
            display: flex;
            flex-direction: column;

            &-RatingItem {
                display: none;
            }

            &-RatingSummary {
                margin-block-end: 5px;
            }

            &-ReviewAuthor {
                color: #666;
                font-size: 14px !important;
            }
        }
    }

    .ProductConfigurableAttributes {
        margin-block-end: 15px !important;
        display: block;

        .ProductConfigAttributesWrap {
            margin-block-end: 12px;
            width: 340px;

            .css-1s2u09g-control,
            .css-1pahdxg-control,
            .css-3iigni-container {
                border: 1px solid var(--button-border);
                box-shadow: none;
                border-radius: 5px;

                &:hover {
                    border: 1px solid var(--button-border);
                }

                input[type="text"] {
                    height: auto;
                }
            }

            .css-26l3qy-menu {
                * {
                    background-color: transparent !important;
                    color: #333;
                }

                > div > div {
                    cursor: pointer;

                    &:hover {
                        background-color: var(--select-option-hover-background) !important;
                    }
                }
            }
        }

        &-Title {
            font-family: "Regular-Book", sans-serif;
            font-weight: normal;
            font-size: 16px;
            margin: 0 0 5px;
        }
    }

    .ProductConfigurableAttributeDropdown {
        margin-block: 0 15px;
    }

    .yotpo {
        .promoted-products-box {
            .yotpo-main-title {
                font-size: 25px;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 0;

                @include mobile {
                    font-size: 22px;
                    text-align: left;
                    margin-bottom: 20px;
                    margin-top: 0;
                }
            }

            ul.promoted-products li {
                background: #ededed !important;
                border: 1px solid #e2e2e2;
                border-bottom: 2px solid #e2e2e2;
                padding: 6px;
            
                &::before {
                    display: none;
                }
            
                .yotpo-promoted-product {
                    width: 100%;
                    margin: 0;

                    .yotpo-product-image {
                        height: auto;
                    }
                }
            }
        }

        &.yotpo-shoppers-say .shoppers-say-title {
            margin-top: 16px;
        }

        &.bottomLine {
            min-height: 18px;
            margin-top: 2px;
            margin-left: -2px;

            &.yotpo_en_de, &.yotpo_de_de, &.yotpo_fr_be, &.yotpo_es_be, &.yotpo_nl_be, &.yotpo_en_uk {
                margin-top: 8px;
            }

            a {
                text-decoration: underline !important;

                &:hover {
                    color: var(--text-red-color) !important;
                }
            }
        }

        &.yotpo-main-widget {
            margin-top: 15px;
        }
    }

    .warningText {
        font-size: 14px;
        background-image: url('../../images/warning.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 24px;
        padding: 12px 0 12px 32px;
        margin-top: 15px;
        color: #333;

        a {
            color: #333;
            text-decoration: underline;

            &:hover {
                color: #000;
            }
        }
    }
}

.yotpo.yotpo-main-widget.yotpo-small .promoted-products-box div.yotpo-promoted-products {
    @include mobile {
        margin: 0;
    }
}
