/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable indentation */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-cart-picture-label-background: rgba(255, 255, 255, 0.7);
}

.ProductCard {
    padding-left: 0;
    min-width: 0;
    text-align: center;
    list-style: none;
    border-radius: 4px;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0.6rem;
    }

    @include desktop {
        &:hover &-Picture img {
            transform: scale(1.2);
            transform-origin: 50% 50%;
            transition: transform 0.5s, visibility 0.5s ease-in;
        }
    }

    &-Content {
        display: flex;
        flex-wrap: wrap;
    }

    &-Name {
        font-size: 13px;
        color: #444;
        font-family: regular-book, sans-serif;
        text-transform: uppercase;
        line-height: 1.1;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &-Text {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            text-transform: unset;
            margin: 0;
            font-family: regular-bold, sans-serif;
            font-weight: var(--h2-font-weight);
            font-style: var(--h2-font-style);
        }

        .more_info .ProductCard-Link {
            border-bottom: 1px dotted rgba(222, 47, 12, 0.4);
            display: inline-block;
            line-height: 1;
        }

        &_isLoaded {
            text-overflow: ellipsis;
        }

        @include desktop {
            font-size: 14px;
            line-height: 1.3;
        }
    }

    .ProductPrice {
        font-size: 18px;
        color: #444;
        font-family: regular-bold, sans-serif;
        font-weight: 400;
        width: 100%;
        min-height: unset;

        @include desktop {
            font-size: 20px;
        }

        .price-new {
            display: none;
        }

        .price-current {
            display: block;
        }

        .price-symbol {
            inset-block-start: -7px;
            right: 1px;
            font-size: 14px;
            font-weight: 400;
        }

        .price-whole {
            font-size: 26px;
            font-weight: 400;
        }

        .price-decimal {
            top: -7px;
            left: 1px;
            font-size: 14px;
            font-weight: 400;
        }

        .offscreen {
            position: absolute !important;
            inset-inline-start: 0 !important;
            inset-block-end: -1px !important;
            z-index: -1 !important;
            opacity: 0;
        }

        .price-box-text {
            display: block;
            font-size: 10px;
            line-height: 1;
            margin: 1px 0 8px 0;
            color: gray;
            font-weight: normal;

            @include desktop {
                font-size: 9px;
            }
        }
    }

    &-Stock {
        font-family: regular-bold, sans-serif;
        line-height: 11px;
        color: #444;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0;
        width: 100%;
    }

    &-Stock.outofstock {
        color: #888;
        font-size: 15px;
        border: 2px solid #ccc;
        line-height: 30px;
        padding: 0 12px;
        display: inline-block;
        width: auto;
        border-radius: 999px;
    }

    &-StockText {
        font-size: 16px;
        color: #7f8d26;
        font-family: regular-book, sans-serif;
        font-weight: 300;
        margin: 5px 0 8px 0;

        .out-of-stock {
            color: #777777;
        }

        @include mobile {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    .pro-inner-wrap {
        width: 100%;
        margin: 4px 0 8px;
        min-height: 30px;

        @include desktop {
            margin: 0;
            min-height: unset;
            width: auto;
        }

        .ProductPrice {
            margin-block-end: 0;

            .price-current {
                display: flex;
                align-items: center;
                justify-content: center;

                del {
                    margin-left: 5px;
                }
            }

            ins {
                text-decoration: none;
            }

            .ProductPrice-HighPrice {
                del {
                    margin-block-start: 2px;
                    -webkit-margin-end: 0;
                }

                .ProductPrice-SaveDiscount {
                    display: none;
                }
            }
        }
    }

    .add_cart_wrap {
        .item_added {
            color: #008000;
            font-family: regular-bold, sans-serif;
            inset-block-start: -1px;
            font-size: 15px;
            border: 2px solid #008000;
            display: inline-block;
            padding: 0 13px;
            line-height: 30px;
            text-transform: uppercase;
            border-radius: 999px;

            @include mobile {
                margin: 0 0 10px;
                width: 100%;
                line-height: 34px;
            }
        }
    }

    &-Name {
        @include desktop {
            margin-block-start: 0.5rem;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-Brand {
        font-family: regular-book, sans-serif;
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        margin-block-start: 2px;
        color: #777777;

        @include mobile {
            //display: none;
            font-size: 13px;
        }
    }

    &-Picture {
        --placeholder-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.04) 0,
            rgba(0, 0, 0, 0.08) 15%,
            rgba(0, 0, 0, 0.04) 30%
        );

        padding-block-end: 75%;
        background-color: #fff;

        img {
            transition: transform 0.35s ease-in-out;
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block: 0;
        margin: auto;
    }

    &-Reviews {
        // --product-rating-size: 12px;
        // display: none;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: -7px 0 8px;
    }

    &-ConfigurableOptions {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-Color {
        --product-card-color-size: 9px;

        @include desktop {
            --product-card-color-size: 10px;
        }

        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-left: calc(var(--product-card-color-size) / 3);
        border: 1px solid var(--product-card-color-border-color);
    }

    &-Price {
        @include desktop {
            font-size: 20px;
        }
    }

    &-Figure {
        flex-grow: 1;
        line-height: 0;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        &:hover,
        &:focus {
            text-decoration: none;
            color: var(--text-red-color);
        }
    }

    p {
        margin-block-end: 0;
    }

    &-SKU {
        flex: 1;
        font-family: regular-book, sans-serif;
        font-weight: 300;
        color: #444;
        font-size: 14px;
        line-height: 1.2;

        &:hover {
            text-decoration: underline;
        }
    }

    .freeshippingindicator {
        &-item {
            background: var(--text-blue-color);
            padding: 2px 5px;
            position: absolute;
            right: 3px;
            bottom: 3px;
            line-height: 15px;
            text-align: center;
            border-radius: 5px;
            opacity: 1;
            z-index: 1;
            font-size: 12px;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: #fff;

            svg {
                font-size: 12px;
                color: #fff;
                margin-top: 1px;
            }

            &:hover {
                opacity: 1;
            }
        }

        &-tooltip {
            font-size: 12px;
            padding: 4px 5px;
            max-width: 160px;
            z-index: 10;
        }
    }

    .review_rating {
        display: block;
        flex: 1;
        font-family: regular-book, sans-serif;
        font-weight: 300;
        color: #444;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;

        a {
            color: inherit;

            &:hover {
                text-decoration: underline !important;
            }
        }

        svg {
            margin-right: 3px;
            color: #F0C332;
        }

        span {
            margin-left: 4px;
            color: #777;
        }

        @include mobile {
            margin-top: 5px;
        }
    }

    @include desktop {
        &:hover {
            .product-wishlist {
                display: block;
            }   
        }
    }

    .wishlistText {
        display: none;
    }
}

.product-wishlist {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    display: none;
    
    @include mobile {
        display: block;
    }

    button.Button {
        background-color: rgba(255, 255, 255, 0.8) !important;
        border: none;
        padding: 4px !important;
        height: auto !important;
        font-family: regular-book, sans-serif;
        color: #444 !important;
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 0 !important;
        text-align: center;
        line-height: 17px;

        svg {
            font-size: 17px;
            color: #999;
            opacity: 0.5;
        }
        
        &:hover {
            svg {
                color: var(--text-red-color);
                opacity: 1;
            }
        }

        @include desktop {
            line-height: 1.2;
        }
    }

    .ProductWishlistButton-Heart {
        display: none;
    }
}

.fr_prod_promo_label,
.fr_prod_new_label,
.fr_prod_coming_soon_label,
.fr_prod_best_seller_label {
    position: absolute;
    top: 3px;
    left: 3px;
    background: #222;
    color: #fff;
    line-height: 1;
    font-weight: 400;
    border: 1px solid #222;
    text-align: center;
    width: auto;
    transform-origin: center center;
    font-family: regular-bold, sans-serif;
    text-transform: uppercase;
    user-select: none;
    font-size: 13px;
    padding: 2px 5px;
    z-index: 1;
    border-radius: 5px;

    &.discount_label_hide {
        display: none;
    }

    .fr_prod_new_label
    .fr_prod_coming_soon_label,
    .fr_prod_best_seller_label {
        background: #5188ae;
        border: 1px solid #5188ae;
    }

    // &::after {
    //     content: "";
    //     inset-block-start: -1px;
    //     transform: translateX(100%);
    //     width: 220px;
    //     height: 20px;
    //     position: absolute;
    //     z-index: 1;
    //     animation: slide 4s infinite 3s;
    //     background: linear-gradient(
    //         to right,
    //         rgba(255, 255, 255, 0) 0%,
    //         rgba(255, 255, 255, 0.5) 50%,
    //         rgba(128, 186, 232, 0) 99%,
    //         rgba(125, 185, 232, 0) 100%
    //     );
    // }
}

.fr_prod_coming_soon_label {
    background: var(--text-orange-color);
    border: 1px solid var(--text-orange-color);
}

// .fr_prod_promo_label {
//     background: #C49436;
//     border: 1px solid #C49436;
    
//     &.discount_label_hide {
//         display: none;
//     }
// }

@keyframes slide {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}
